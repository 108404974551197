import React from 'react';
import PropTypes from 'prop-types'
import Lock from '../../../../../assets/images/icons/lock.svg'
import DocumentOrange from '../../../../../assets/images/icons/documents-orange.png'
import _ from 'lodash'
import {Link, Redirect} from "react-router-dom";
import ProductButton from "../../../../Commun/Buttons/ProductButton/ProductButton";
import TopArticlesContainer from "./TopArticles/TopArticlesContainer";
import LibertyRider from "../../../../../assets/images/liberty-rider.png"
import MotoScooterLabel from "../../../../../assets/images/label-excellence-2024.png"
import Particules from '../../../../../assets/images/particules.png'


const Accueil = (props) => {
    const { mustPassAPRILON, infosAprilOn, userRoles, permissions, subscribeLinks, subscribeRoutes, isConcessionnaire, isCourtier, isConcessionnaireGP, belairId, pmqRemind } = props

    if(!isCourtier && !isConcessionnaire && !isConcessionnaireGP){
        return <Redirect to={"/mon-portefeuille"} />
    }

    const checkRoles = route => {
        if (route.roles){
            let hasRole = false;
            _.map(userRoles, ur => {
                if(_.includes(route.roles, ur)){
                    hasRole = true;
                }
            });

            return hasRole
        }

        return true;
    }

    return (
        <div className={'pb-5 bg-white'}>
            <div className={'background-primary py-5 px-4'}>

                {
                    !isCourtier && infosAprilOn && <div className="col-12 mb-4 d-lg-flex rounded text-white align-items-center p-2"
                                         style={{background: '#629f31'}}>
                        <div className="col-12 col-lg-2 text-center">
                            <span className={'bg-white rounded-circle d-inline-block px-4'} style={{color: '#629f31',fontSize: '40px'}}>
                                !
                            </span>
                        </div>
                        <div className="col px-2">
                            <b>Devis d'assurance</b><br/>
                            Réalisez vos devis sur <b>APRIL ON</b>, rubrique Offres &gt; Le dommage &gt; 2 roues et quad
                        </div>
                        <div className="col-12 col-lg-3 text-right">
                            <a href={'https://www.april-on.fr/contracts-and-quotes/summary'} target={'_blank'} style={{color: '#629f31'}} className={"btn btn-light"}>Faire un devis</a>
                        </div>
                    </div>
                }

                {
                    isCourtier && pmqRemind !== "T" &&
                    <div className="col-12 mb-4 d-lg-flex rounded text-white align-items-center p-2"
                         style={{background: '#f49e00'}}>
                        <div className="col-12 col-lg-2 text-center">
                            <span className={'p-2 bg-white rounded-circle d-inline-block'}>
                                <img src={DocumentOrange} alt="Documents" width={44}/>
                            </span>
                        </div>
                        <div className="col px-2">
                            <b>Optimisez la validation de vos contrats en établissement avec notre service de relance
                                des pièces manquantes&nbsp;!</b>
                        </div>
                        <div className="col-12 col-lg-4 text-right">
                            <Link to={'/mes-services'} style={{color: "#f49e00"}} className={"btn btn-light"}>Découvrir le service</Link>
                        </div>
                    </div>
                }
                <div className="row px-lg-5">
                    {/*<div className="col d-none d-lg-block">
                        <h4>APRIL Moto récompensé par les Dossiers de l'Epargne</h4>
                        <div className="mt-5 d-flex">
                            <img src={img_welcome_pro} alt="Accueil" width={170} height={218} style={{minWith: 170}}/>
                            <div className={'p-5 ml-4 d-inline-block align-middle w-75'}>
                                <p className={'m-0'}>{description_welcome}</p>
                                {link_btn_welcome && <Link to={link_btn_welcome} className={'mt-4 btn btn-block max-width-3 btn-outline-secondary max-rounded'}>{title_btn_welcome}</Link>}
                            </div>
                        </div>
                    </div>*/}

                    <div className="col">
                        {
                            _.includes(permissions, 'read contracts') && <Link to={'/mon-portefeuille'}>
                                <div className={'border background-primary text-dark p-4 mb-4 hover-translateY hover-border-secondary hover-span-color-secondary'}>
                                    <h4>Accédez à votre portefeuille</h4>
                                    <p className={'mt-3'}>Vous pouvez envoyer vos documents, faire des avenants, payer les cotisations, <br/> déclarer des sinistres... gérer l'ensemble de votre contrat simplement&nbsp;!</p>
                                    <span className="float-right">Voir mon portefeuille <i className="fas fa-long-arrow-alt-right" /></span>
                                </div>
                            </Link>
                        }

                        {
                            mustPassAPRILON ? <a href={'https://www.april-on.fr/'} target={'_blank'}>
                                <div className={'border background-primary text-dark p-4 hover-translateY hover-border-secondary hover-span-color-secondary'}>
                                    <h4>Devis d'assurance uniquement sur APRIL ON</h4>
                                    <ul className={'mt-3'}>
                                        <li>Réalisez vos devis : rubrique Offre &gt; Le dommage &gt; 2 roues et quad</li>
                                        <li>Consultez, modifiez, téléchargez et souscrivez vos devis : rubrique Devis & Contrats</li>
                                    </ul>
                                    <span className="float-right">Je me connecte à APRIL ON <i className="fas fa-long-arrow-alt-right" /></span>
                                </div>
                            </a>
                                : <Link to={'/mes-devis'}>
                                    <div className={'border background-primary text-dark p-4 hover-translateY hover-border-secondary hover-span-color-secondary'}>
                                        <h4>Consultez vos devis</h4>
                                        <p className={'mt-3'}>Retrouvez l'ensemble de vos devis 2-roues réalisés avec APRIL Moto.<br/>
                                            Vous pouvez les consulter, les modifier, les télécharger et les souscrire&nbsp;!</p>
                                        <span className="float-right">Voir mes devis <i className="fas fa-long-arrow-alt-right" /></span>
                                    </div>
                                </Link>
                        }

                        {
                            !_.includes(permissions, 'read contracts') ?
                                isCourtier ? <a href="https://www.april-on.fr/landings/label-excellence" target={'_blank'}>
                                        <div className={'pl-3 mt-4 border background-primary text-dark hover-translateY hover-border-secondary hover-span-color-secondary'} style={{height: 152}}>
                                            <img src={MotoScooterLabel} alt="Accueil" width={90} className={"align-top pt-3"}/>
                                            <p className={'mt-3 p-4 w-75 d-inline-block'}>Les experts des Dossiers de l'Épargne ont décerné le Label d'Excellence 2023 à l'Assurance Moto & Scooter d’APRIL Moto. Notre offre se démarque grâce à la qualité de ses prestations et son positionnement tarifaire compétitif.</p>
                                            <span className="position-absolute" style={{bottom: 0, right: 40}}>Voir l'article <i className="fas fa-long-arrow-alt-right" /></span>
                                        </div>
                                    </a>
                                    : <Link to="/mon-article/13">
                                <div className={'pl-3 mt-4 border background-primary text-dark hover-translateY hover-border-secondary hover-span-color-secondary'} style={{height: 152}}>
                                    <img src={MotoScooterLabel} alt="Accueil" width={90} className={"align-top pt-3"}/>
                                    <p className={'mt-3 p-4 w-75 d-inline-block'}>Les experts des Dossiers de l'Épargne ont décerné le Label d'Excellence 2023 à l'Assurance Moto & Scooter d’APRIL Moto. Notre offre se démarque grâce à la qualité de ses prestations et son positionnement tarifaire compétitif.</p>
                                    <span className="position-absolute" style={{bottom: 0, right: 40}}>Voir l'article <i className="fas fa-long-arrow-alt-right" /></span>
                                </div>
                            </Link> : null
                        }

                    </div>

                    <div className="col">

                        {
                            _.includes(permissions, 'read contracts') ? <a target='_blank' href="https://www.april-on.fr/landings/APRIL-Moto-Liberty-Rider">
                                    <img src={LibertyRider} alt="Liberty Rider" className={"hover-translateY w-100 cursor-pointer"} style={{marginBottom: 9}}/>
                                </a>
                                : <img src={LibertyRider} alt="Liberty Rider" className={"hover-translateY w-100 cursor-pointer"} style={{marginBottom: 9}}/>
                        }

                        {
                            _.includes(permissions, 'read contracts') ?
                                isCourtier ? <a href="https://www.april-on.fr/landings/label-excellence">
                                        <div
                                            className={'pl-3 border background-primary text-dark hover-translateY hover-border-secondary hover-span-color-secondary'}
                                            style={{height: 152}}>
                                            <img src={MotoScooterLabel} alt="Accueil" width={90} className={"align-top pt-3"}/>
                                            <p className={'mt-3 p-4 w-75 d-inline-block'}>Les experts des Dossiers de l'Épargne ont décerné le Label d'Excellence 2023 à l'Assurance Moto & Scooter d’APRIL Moto. Notre offre se démarque grâce à la qualité de ses prestations et son positionnement tarifaire compétitif.</p>
                                            <span className="position-absolute" style={{bottom: 0, right: 40}}>Voir l'article <i className="fas fa-long-arrow-alt-right"/></span>
                                        </div>
                                    </a>
                                : <Link to="/mon-article/13">
                                <div
                                    className={'pl-3 border background-primary text-dark hover-translateY hover-border-secondary hover-span-color-secondary'}
                                    style={{height: 152}}>
                                    <img src={MotoScooterLabel} alt="Accueil" width={90} className={"align-top pt-3"}/>
                                    <p className={'mt-3 p-4 w-75 d-inline-block'}>Les experts des Dossiers de l'Épargne ont décerné le Label d'Excellence 2023 à l'Assurance Moto & Scooter d’APRIL Moto. Notre offre se démarque grâce à la qualité de ses prestations et son positionnement tarifaire compétitif.</p>
                                    <span className="position-absolute" style={{bottom: 0, right: 40}}>Voir l'article <i className="fas fa-long-arrow-alt-right"/></span>
                                </div>
                            </Link> : null
                        }

                    </div>
                </div>
            </div>
            {
                mustPassAPRILON && <div className={'py-5 px-4'}>
                    <div className={'row'}>
                        <div className="col">
                            <p className={'h4 mx-5'}><span
                                className={'text-color-secondary font-weight-bold'}>Les produits</span> Collection &
                                Loisirs<Link
                                    to={'/produits/collection'}
                                    target={'_blank'}
                                    className={"ml-4 btn btn-outline-secondary max-rounded px-5"}>Voir nos offres</Link>
                            </p>
                            <p className="mx-5 mt-4 mb-0">APRIL Collection & Loisirs, c’est la <b>garantie de bénéficier de
                                compétences spécialisées et de services sur mesure</b>, dédiés à l’assurance des
                                véhicules de collection et de loisirs.</p>
                            <p className="mx-5">Notre expertise nous permet d’offrir des
                                solutions adaptées pour protéger et valoriser vos <b>véhicules d’exception</b> en toute
                                sérénité.</p>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col mx-5">
                            <p className="h5 text-color-secondary font-weight-bold">
                                🏍️ Moto Collection
                            </p>
                            <p className={"mt-3 mb-0"}>🔹 Roulez en toute liberté avec notre assurance Moto Collection :
                                sans limitation de kilométrage ni de territoire, cartes grises normales acceptées et
                                prêt de véhicule sans contrainte.</p>
                            <p>🔹 Une offre pensée pour les passionnés : assurez vos motos de plus de 9 ans en usage
                                loisir et profitez de notre formule flotte dès le deuxième véhicule.</p>

                            <a href={subscribeLinks['collection']} target={'_blank'}
                               className="btn btn-secondary max-rounded">Réaliser un devis Moto Collection</a>
                        </div>
                    </div>

                    <hr/>
                    <div className="row">
                        <div className="col mx-5">
                            <p className="h5 text-color-secondary font-weight-bold">
                                🚘 Auto Collection
                            </p>
                            <p className={"mt-3 mb-0"}>🔹 Conduisez l’esprit libre avec notre assurance Auto Collection : aucun kilométrage ni frontière imposés, cartes grises classiques acceptées et prêt de volant en toute simplicité.</p>
                            <p>🔹 Une couverture sur mesure pour les amateurs d’autos anciennes : assurez vos véhicules de plus de 9 ans pour un usage plaisir et bénéficiez de notre formule flotte dès le deuxième véhicule.</p>

                            <a href={subscribeLinks['collection']} target={'_blank'}
                               className="btn btn-secondary max-rounded">Réaliser un devis Auto Collection</a>
                        </div>
                    </div>

                    <hr/>
                    <div className="row">
                        <div className="col mx-5">
                            <p className="h5 text-color-secondary font-weight-bold">
                                🚐 Camping-Car
                            </p>
                            <p className={"mt-3 mb-0"}>🔹 Partez l’esprit tranquille avec notre assurance Camping-Car : 3 formules au choix (Tiers, Tiers + Vol/Incendie, Tous Risques) pour tous les camping-cars et fourgons aménagés, même au-delà de 3,5 tonnes.</p>
                            <p>🔹 Une protection complète pour vos voyages : garantie du conducteur, assistance 24h/24, couverture des effets personnels jusqu’à 3 050 € et des équipements jusqu’à 10 000 € par événement.</p>

                            <a href={subscribeLinks['campingcar']} target={'_blank'}
                               className="btn btn-secondary max-rounded">Réaliser un devis Camping-Car</a>
                        </div>
                    </div>

                    <hr/>
                    <div className="row">
                        <div className="col mx-5">
                            <p className="h5 text-color-secondary font-weight-bold">
                                ✨️ Prestige
                            </p>
                            <p className={"mt-3 mb-0"}>🔹 Assurez l’excellence avec notre assurance Véhicules de Prestige : pour des véhicules d’une valeur à partir de 40 000 €, acceptés dès 9 ans, avec des conditions adaptées aux conducteurs expérimentés.</p>
                            <p>🔹 Une couverture sur mesure pour des voitures d’exception : réservée aux conducteurs avec un bonus ≤ 0,8, 5 ans de permis minimum, maximum 2 sinistres (hors bris de glace, non responsable) et un usage privé exclusif.</p>

                            <a href={subscribeLinks['collection']} target={'_blank'}
                               className="btn btn-secondary max-rounded">Réaliser un devis Prestige</a>
                        </div>
                    </div>
                </div>
            }

            {
                !mustPassAPRILON &&
                <div style={{background: '#e9edee'}} className={'position-relative overflow-hidden'}>
                    <div className={'px-3 py-5'} style={{
                        background: `url(${Particules})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}>
                        {_.includes(permissions, 'create quote') && <div className={'px-lg-5 row'}>
                            <div className="row">
                                <div className="col">
                                    {
                                        !isCourtier && infosAprilOn ? <p className={'h4 mb-4'}><span
                                            className={'text-color-secondary font-weight-bold'}>Mon devis d'assurance</span> en
                                            2 min&nbsp;!&nbsp;<a
                                                href={'https://www.april-on.fr/contracts-and-quotes/summary'}
                                                target={'_blank'} style={{color: '#f49e00', borderColor: '#ffc107'}}
                                                className={"btn btn-light font-weight-bold"}>Je me connecte à APRIL
                                                ON</a>
                                        </p> : <p className="h4 mb-4">Mon devis d'assurance <span
                                            className="text-color-secondary font-weight-bold">en 2 minutes</span>&nbsp;!
                                        </p>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                {
                                    subscribeRoutes.map(sr => (
                                        subscribeLinks.hasOwnProperty(sr.type) && checkRoles(sr) &&
                                        <div className="col-lg-3 col-sm-6 my-3" key={sr.path}>
                                            <ProductButton to={sr.path} png={sr.png} pngSelect={sr.pngSelect}
                                                           title={sr.title}/>
                                        </div>
                                    ))
                                }
                                {/* Bouton 2-roues electrique en dur car pas de produit paddock */}
                                <div className="col-lg-3 col-sm-6 my-3">
                                    <ProductButton color={'primary'}
                                                   to={_.find(subscribeRoutes, ['type', 'electrique']).path}
                                                   png={_.find(subscribeRoutes, ['type', 'electrique']).png}
                                                   pngSelect={_.find(subscribeRoutes, ['type', 'electrique']).pngSelect}
                                                   title={_.find(subscribeRoutes, ['type', 'electrique']).title}/>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            }

            { !isCourtier && <TopArticlesContainer/> }

        </div>
    );
};

Accueil.defaultProps = {
    imgHeader: '#',
    permissions: [],
    subscribeLinks: {},
    subscribeRoutes: []
}

Accueil.propTypes = {
    imgHeader: PropTypes.string,
    permissions: PropTypes.array,
    subscribeLinks: PropTypes.object,
    subscribeRoutes: PropTypes.array
}

export default Accueil;

