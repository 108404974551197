import {createSelector} from 'reselect'
import _ from "lodash";

const commissionsSelector = state => state.commissions

export const getDataCommissions = createSelector(
    commissionsSelector,
    // "2024-12-01" --> transform it to Date to extract the year
    commissions => _.groupBy(_.map(commissions.data, c => ({
        ...c,
        year: new Date(c.startDate).getFullYear()
    })), 'year')
)

export const getDadsCommissions = createSelector(
    commissionsSelector,
    commissions => commissions.dads
)
