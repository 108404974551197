import {LOGIN, LOGOUT, POST_LOGIN, POST_LOGOUT, POST_UPDATE_PASSWORD} from '../../../actions/app/login/login.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setIsLogin, setIsLogout, setLoader} from '../../../actions/app/ui/ui.actions'
import {clearLocalStorage, setLocalStorage} from '../../../actions/core/localStorage/localStorage.actions'

import {setUser} from "../../../actions/app/user/user.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {
    PASS_OAUTH_URL,
    APP_CLIENT_ID,
    APP_GRANT_TYPE,
    APP_PROVIDER
} from "../../../../constants/constants";
import React from "react";

export const loginMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){
        case POST_LOGIN:
            const { username, password, token, source} = payload.body
            const data = payload.body.username ? {username, password, client_id: APP_CLIENT_ID, grant_type: APP_GRANT_TYPE, provider: APP_PROVIDER, scope: '*'} : {token, client_id: APP_CLIENT_ID, grant_type: 'aprilon', provider: APP_PROVIDER, scope: '*'}

            next([
                apiRequest({body: (data), method: 'POST', url: payload.data, entity: LOGIN, otherData: {login: true}}),
                setLocalStorage({entity: LOGIN, storage: source, name: 'source'}),
                setLoader({state: true, entity: LOGIN})
            ])
            break

        case `${LOGIN} ${API_SUCCESS}`:
            const {
                token_type,
                access_token,
                classement_zarco,
                title_zarco,
                img_zarco,
                user
            } = payload.data

            next([
                setLocalStorage({entity: LOGIN, storage: {
                        token: `${token_type} ${access_token}`,
                        classement_zarco, title_zarco, img_zarco,
                    }
                }),
                setIsLogin({state: true, entity: LOGIN}),
                setUser({user}),
                setError({state: false, entity: LOGIN}),
                setLoader({state: false, entity: LOGIN}),
            ])
            if (window.localStorage.source === '"aprilon"'){
                const params = window.location.pathname.split("/");

                const search = new URLSearchParams(window.location.search);
                const redirect = search.get('redirect_uri');

                if (redirect) {
                    return window.location = redirect;
                }

                switch (params[3]) {
                    case 'souscriptions':
                        if (user.bu_children.length === 0){
                            window.location = user.subscribe_links[params[4]]
                            return null
                        } else {
                            return window.location = `/mon-formulaire/${params[4]}`
                        }
                    case 'collection':
                    case 'campingcar':
                        return window.location = user.subscribe_links[params[3]]
                    case 'trottinette':
                        if (user.bu_children.length === 0){
                            window.location = user.subscribe_links[params[3]]
                            return null
                        } else {
                            return window.location = `/mon-formulaire/trottinette`
                        }
                    case 'contrats':
                        return params[4] ? window.location = `/mon-contrat/${params[4]}` : window.location = '/mon-portefeuille'
                    case 'devis':
                        return params[4] ? window.location = `/mon-devis/${params[4]}/reprise` : window.location = '/mes-devis'
                    default:
                        return window.location = `/${params[3] ? params[3] : ''}${params[4] ? `/${params[4]}` : ''}`
                }
            }

            break

        case `${LOGIN} ${API_ERROR}`:
            next([
                setError({state: true, entity: LOGIN}),
                clearLocalStorage({entity: LOGIN}),
                setLoader({state: false, entity: LOGIN}),
            ])
            break

        case POST_LOGOUT:
            next([
                apiRequest({method: 'POST', url: payload.data, entity: LOGOUT}),
                clearLocalStorage({entity: LOGOUT}),
                setLoader({state: true, entity: LOGOUT})
            ])
            break

        case `${LOGOUT} ${API_SUCCESS}`:
            next([
                setIsLogout({state: true, entity: LOGOUT}),
                setError({state: false, entity: LOGOUT}),
                setLoader({state: false, entity: LOGOUT})
            ])

            window.location.reload()
            break;

        case `${LOGOUT} ${API_ERROR}`:
            next([
                setError({state: true, entity: LOGOUT}),
                setLoader({state: false, entity: LOGOUT})
            ])
            break;

        case POST_UPDATE_PASSWORD:
            next([
                apiRequest({body: (payload.body), method: 'POST', url: payload.data, entity: POST_UPDATE_PASSWORD, otherData: payload.otherData}),
                setLoader({state: true, entity: POST_UPDATE_PASSWORD})
            ])
            break

        case `${POST_UPDATE_PASSWORD} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_UPDATE_PASSWORD}),
                setLoader({state: false, entity: POST_UPDATE_PASSWORD}),
                apiRequest({
                    body: ({
                        username: payload.data.username,
                        password: payload.meta.otherData.password,
                        client_id: APP_CLIENT_ID, grant_type: APP_GRANT_TYPE, provider: APP_PROVIDER, scope: '*'
                    }),
                    method: 'POST',
                    url: PASS_OAUTH_URL,
                    entity: LOGIN,
                    otherData: {login: true}
                }),
                setLoader({state: true, entity: LOGIN})
            ])
            break;

        case `${POST_UPDATE_PASSWORD} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_UPDATE_PASSWORD}),
                setLoader({state: false, entity: POST_UPDATE_PASSWORD}),
                setNotification({entity: POST_UPDATE_PASSWORD, body: `Une erreur est survenue. ${payload.data.response.data?.message ?? '' ? payload.data.response.data?.message ?? '' : ''}`, type: 'error', title: 'Erreur !', timeout: '0'}),
            ])
            break;

        default:
            break
    }
    return null
}
