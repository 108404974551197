import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../../Commun/Spinners/Loader/Loader'
import ContractDetail from './ContractDetail'
import {
    CONTRACT,
    fetchContract,
    postConfirmResilHamon
} from "../../../../../../redux/actions/app/contracts/contracts.actions";
import {
    confirmationResilHamonGetLink,
    deleteThirdPartyPayerGetLink,
    getContractLink,
    getCustomerCreateLink, getPayDepositLink,
    getQueryDocumentLink
} from "../../../../../../redux/selectors/links/links.selectors";
import _ from 'lodash'
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {
    getContract,
    getIsNVEIContract,
    getIsFleetContract,
    getIsVAEContract,
    getIdSouscripteurContract,
    getQuittanceWithmontantComptantOrFirstQuittanceContract,
    getWaitingDocumentReglementContract,
    getIsACLContract,
    getIsAIMCOContract,
    cantReportAccidentContract,
    getVilleNaissanceSouscripteurContract,
    getPaysNaissanceSouscripteurContract,
    getNomNaissanceSouscripteurContract,
    getTiersPayeurContract,
    getPrelevementContract,
    getStatutCodeContract,
    getIsEligibleHivernageContract,
    getIsWakamContract,
    getDernierHivernageContract, getDateEcheanceContract, getContractHamon, getQuittancesContract
} from "../../../../../../redux/selectors/contracts/contracts.selectors";
import {getPermissionsUser, getUserIsCourtierPremium} from "../../../../../../redux/selectors/user/user.selectors";
import {POPUP, postPopup} from "../../../../../../redux/actions/app/popup/popup.actions";
import {postCreateAccount} from "../../../../../../redux/actions/app/contracts/account.contract.actions";
import {PAYMENT, postPayment} from "../../../../../../redux/actions/app/payment/payment.actions";
import { postDeleteTiersPayeur } from '../../../../../../redux/actions/app/tiers_payeur/tiers_payeur.actions';


const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params

    return {
        contract: getContract(state),
        isWakam: getIsWakamContract(state),
        isFleet: getIsFleetContract(state),
        isNVEI: getIsNVEIContract(state),
        isVAE: getIsVAEContract(state),
        isACL: getIsACLContract(state),
        cantReportAccident: cantReportAccidentContract(state),
        isAIMCO: getIsAIMCOContract(state),
        permissions: getPermissionsUser(state),
        queryDocument: getQueryDocumentLink(state),
        queryAccount: getCustomerCreateLink(state),
        queryDeposit: getPayDepositLink(state),
        query: _.replace(getContractLink(state), '{id}', id),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        paymentLoading: getLoadingEntity(state, PAYMENT),
        error: getErrorEntity(state, CONTRACT),
        isCourtierPremium: getUserIsCourtierPremium(state),
        loadingPopup: getLoadingEntity(state, POPUP),
        hasWaitingReglement: getWaitingDocumentReglementContract(state),
        id_souscripteur: getIdSouscripteurContract(state),
        quittance: getQuittanceWithmontantComptantOrFirstQuittanceContract(state),
        quittances: getQuittancesContract(state),
        villeNaissance: getVilleNaissanceSouscripteurContract(state),
        paysNaissance: getPaysNaissanceSouscripteurContract(state),
        nomNaissance: getNomNaissanceSouscripteurContract(state),
        tiersPayeur: getTiersPayeurContract(state),
        uri_delete_tp: deleteThirdPartyPayerGetLink(state),
        canChangeFractionnement: !getPrelevementContract(state) && getStatutCodeContract(state) === "1", // contrat en cours,
        isEligibleHivernage : getIsEligibleHivernageContract(state),
        dernier_hivernage: getDernierHivernageContract(state),
        dateEcheance: getDateEcheanceContract(state),
        query_resil_hamon: confirmationResilHamonGetLink(state),
        hamon: getContractHamon(state),
    }
}

const mapDispatchToProps = {
    fetchContract: ({query}) => fetchContract({query}),
    postPopup: ({form, query}) => postPopup({form, query, confirmMessage: 'Votre demande a bien été envoyée à nos services de gestion pour envoyer la carte verte par voie postale.'}),
    postCreateAccount: ({email, owner_belair_id, nom, date_naissance, belair_id, query}) => postCreateAccount({email, owner_belair_id, nom, date_naissance, belair_id, query}),
    postPayment: ({id_tiers, amount, url_return, by_mail, query}) => postPayment({id_tiers, amount, url_return, by_mail, query}),
    postDeleteTiersPayeur,
    postConfirmResilHamon
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri_delete_tp, query, queryDocument, queryAccount, quittance, queryDeposit, id_souscripteur, contract } = stateProps
    const { fetchContract, postPopup, postCreateAccount, postPayment, postDeleteTiersPayeur } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postPopup: ({form, type}) => postPopup({form, query: _.replace(queryDocument, '{type}', type)}),
        postAccount: ({email, owner_belair_id, nom, date_naissance, belair_id}) => postCreateAccount({
            email, owner_belair_id, nom, date_naissance, belair_id, query: queryAccount
        }),
        postDeposit: () => postPayment({
            id_tiers: id_souscripteur,
            amount: quittance.montantComptant ?? null,
            by_mail: true,
            query: queryDeposit
        }),
        submitDeleteTiersPayeur: () => postDeleteTiersPayeur({query: _.replace(uri_delete_tp, '{id}', contract.id)}),
        load: () => fetchContract({query})
    }
}

const ContractDetailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(ContractDetail)

export default ContractDetailContainer
