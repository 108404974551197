import { createSelector } from 'reselect'
import _ from "lodash";

const userSelector = state => state.user

export const getUserData = createSelector(
    userSelector,
    user => user
)

export const getWhitelabelUser = createSelector(
    userSelector,
    user => user.whitelabel
)

export const getRolesUser = createSelector(
    userSelector,
    user => user.roles
)

export const getUserIsCourtier = createSelector(
    getRolesUser,
    roles => _.includes(roles, 'courtier')
)

export const getUserIsConcessionnaire = createSelector(
    getRolesUser,
    roles => _.includes(roles, 'concessionnaire')
)

export const getUserIsConcessionnairePremium = createSelector(
    getRolesUser,
    roles => _.includes(roles, 'concessionnaire premium')
)

export const getUserIsConcessionnaireGP = createSelector(
    getRolesUser,
    roles => _.includes(roles, 'concessionnaire gp')
)

export const getUserIsCourtierPremium = createSelector(
    getRolesUser,
    roles => _.includes(roles, 'courtier premium')
)

export const getUserIsFlotte = createSelector(
    getRolesUser,
    roles => _.includes(roles, 'gestion flotte')
)

export const getBuNameUser = createSelector(
    userSelector,
    user => user.bu_name
)

export const getNameUser = createSelector(
    userSelector,
    user => user.name
)

export const getPermissionsUser = createSelector(
    userSelector,
    user => user.permissions
)

export const getSubscribeLinksUser = createSelector(
    userSelector,
    getRolesUser,
    (user, roles) => {
        if ( _.includes(roles,'concessionnaire') || _.includes(roles,'concessionnaire premium') || _.includes(roles,'concessionnaire gp')){
            let newObj = {};

            for (let key in user.subscribe_links) {
                if (key !== 'collection') {
                    newObj[key] = user.subscribe_links[key];
                }
            }

            return newObj
        } else {
            return user.subscribe_links
        }
    }
)

export const getBuChildrenUser = createSelector(
    userSelector,
    user => user.bu_children
)

export const getEmailUser = createSelector(
    userSelector,
    user => user.email
)

export const getAddressCityUser = createSelector(
    userSelector,
    user => user.address_city
)

export const getAddressPhoneUser = createSelector(
    userSelector,
    user => user.address_phone
)

export const getAddressPostalCodeUser = createSelector(
    userSelector,
    user => user.address_postalcode
)

export const getAddressStreet1User = createSelector(
    userSelector,
    user => user.address_street_1
)

export const getAddressStreet2User = createSelector(
    userSelector,
    user => user.address_street_2
)

export const getLogoUser = createSelector(
    userSelector,
    user => user.logo
)

export const getNewsletterUser = createSelector(
    userSelector,
    user => user.newsletter
)

export const getOroBuIdUser = createSelector(
    userSelector,
    user => user.oro_bu_id
)

export const getBelairIdUser = createSelector(
    userSelector,
    user => user.belair_id
)

export const getLastDateCharterSigned = createSelector(
    userSelector,
    user => user.lastDateCharterSigned
)
export const getPMQRemind = createSelector(
    userSelector,
    user => user.pmq_remind
)

export const getUserMustRedirectAprilOn = createSelector(
    userSelector,
    user => {
        return user.bu_belair_id !== 672616 && _.includes(user.roles, 'courtier')
    }
)

export const getMustPassByAPILON = createSelector(
    getUserIsCourtier,
    getBelairIdUser,
    (isCourtier, idBelair) => isCourtier && ![120134, 479706, 101380].includes(idBelair)
);
