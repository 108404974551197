import { connect } from 'react-redux';
import Resiliation from "./Resiliation";
import { postTerminate } from "@/redux/actions/app/terminate/terminate.actions";
import _ from "lodash";
import { getAccidentsContractLink, getContractLink, terminateGetLink } from "@/redux/selectors/links/links.selectors";
import { compose } from "redux";
import { change, getFormValues, reduxForm } from "redux-form";
import { fetchContract } from "@/redux/actions/app/contracts/contracts.actions";
import { getContract } from "@/redux/selectors/contracts/contracts.selectors";
import { fetchAccidentsContract } from "@/redux/actions/app/contracts/accidents.contract.actions";

const mapStateToProps = (state, ownProps) => {
    const contract = getContract(state);
    return {
        formValues: getFormValues('resil-3clics')(state),
        uriContract: getContractLink(state),
        idContract: ownProps.match.params.id,
        contract: contract,
        query: getAccidentsContractLink(state),
        queryTerminate: terminateGetLink(state),
        initialValues: {
            police: contract.numeroPolice || '',
            adhesion: contract.adhesionPrincipale || '',
            prenom: contract.souscripteur.firstname || '',
            nom: contract.souscripteur.lastname || '',
            email: contract.souscripteur.email || '',
            files: {},
        }
    };
}

const mapDispatchToProps = {
    fetchAccidentsContract,
    fetchContract,
    postTerminate,
    change
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchAccidentsContract, postTerminate, fetchContract, change } = dispatchProps;
    const { query, queryTerminate, idContract, uriContract } = stateProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        load: () => {
            fetchAccidentsContract(_.replace(query, '{id}', idContract));
            fetchContract(_.replace(uriContract, '{id}', idContract));
        },
        onSubmit: (form) => postTerminate({ query: _.replace(queryTerminate, '{id}', idContract), form: form, otherData: { idContract }  }),
        changeValue: (field, value) => change('resil-3clics', field, value)
    };
}

const ResiliationContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'resil-3clics',
    })
)(Resiliation);

export default ResiliationContainer;
