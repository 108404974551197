import React from 'react'
import Swal from 'sweetalert2'
import _ from "lodash";
import {getReason} from "@/utils/translate";
import APILink from "@/components/Commun/APILink/APILink";
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const DocumentsClient = ({contract, ri_link, ri_link_reason, quittance, flotte, vehicule, mostRecentQuittance}) => {

    const showDocuments = () => {
        MySwal.fire({
            title: 'Les documents de mon client',
            color: '#c9d200',
            padding : "2px",
            html: (
                <div>
                    {flotte &&
                        <APILink className={"btn btn-ternary max-rounded d-block d-lg-inline-block mt-3 mb-3 mt-lg-0"} href={vehicule.memo_link} download target={'_blank'}>
                            <i className={'fas fa-file-pdf'}/> Télécharger le Mémo
                        </APILink>
                    }
                    {
                        !flotte && !_.includes(['6', '5', 'I'], contract.situation.statutCode) && <>
                            {(quittance?.memo_link && !_.isEmpty(contract.vehicule.immatriculation)) &&
                                <APILink className={"btn btn-ternary max-rounded d-block d-lg-inline-block mt-3 mb-3 mt-lg-0"} href={quittance.memo_link} download target={'_blank'}>
                                    <i className={'fas fa-file-pdf'}/> Télécharger le Mémo
                                </APILink>
                            }

                            {mostRecentQuittance?.attestation_assurance_link &&
                                <APILink className={"btn btn-ternary max-rounded d-block d-lg-inline-block mt-3 mb-3 mt-lg-0"} href={quittance.attestation_assurance_link} download target={'_blank'} >
                                    <i className={'fas fa-file-pdf'}/> Télécharger l'Attestation d'assurance
                                </APILink>
                            }
                        </>
                    }

                    {
                        (!flotte && ri_link) && <div className="block">
                            <APILink
                                href={_.startsWith(contract.vehicule.codeProduit, '229127_') ? null : ri_link}
                                download
                                className={`${(!ri_link || _.startsWith(contract.vehicule.codeProduit, '229127_')) && 'disabled amo-tooltip'} btn btn-ternary max-rounded d-block d-lg-inline-block mt-3 mb-3 mt-lg-0`}
                            >
                                <i className={'fas fa-file-pdf'}/> Editer un RI
                                <small
                                    className={'tooltiptext'}>{ri_link_reason.length > 0 ? getReason(ri_link_reason) : 'Action impossible sur ce contrat'}</small>
                            </APILink>
                        </div>
                    }
                    { (!flotte && quittance?.attestation_rc_circuit_link) && <APILink className={"btn btn-ternary max-rounded d-block d-lg-inline-block mt-3 mb-3 mt-lg-0"} href={quittance.attestation_rc_circuit_link} target={'_blank'} download>
                            <i className={'fas fa-file-pdf'}/> Télécharger l'attestation RC Roulage Circuit
                        </APILink>
                    }
                </div>
            ),
            showConfirmButton: false,
            showCloseButton: true
        });
    };

    return (
        <span className={"d-block d-lg-inline-block btn btn-primary max-rounded mr-0 mr-lg-3 mt-3 mb-3 mt-lg-0"}
              onClick={() => showDocuments()}>Les documents de mon client</span>
    );
};

export default DocumentsClient;