import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import Card from '../../../../../../../Commun/Card/Card';
import Cadre from '../../../../../../../Commun/Cadre/Cadre';
import { Field } from 'redux-form';
import TextFieldRedux from '../../../../../../../Commun/Inputs/Text/TextFieldRedux';
import PostalCodeCity from '../../../../../../../Commun/Inputs/PostalCodeCity/PostalCodeCity';
import SmallSpinner from '../../../../../../../Commun/Spinners/SmallSpinner';
import { checkIban, getCountries } from '../../../../../../../../utils/function';
import DatePickerFieldRedux from '../../../../../../../Commun/Inputs/Date/DatePickerFieldRedux';
import moment from 'moment';
import Autocomplete from '../../../../../../../Commun/Inputs/Autocomplete/Autocomplete';
import SelectFieldRedux from '../../../../../../../Commun/Inputs/Select/SelectFieldRedux';
import { tiersPayeurRelation } from '../../../../../../../../utils/optionsSelect';
import RadioFieldRedux from '../../../../../../../Commun/Inputs/Radio/RadioFieldRedux';
import UploadInlineContainer from "@/components/Commun/Inputs/Upload/UploadInline/UploadInlineContainer";
import Swal from "sweetalert2";


import IbanField from "@/components/Commun/Inputs/IbanField/IbanField";

const ModifieRib = (props) => {
    const { handleSubmit, iban, nomSouscripteur, changeValue, loading, ibanIsCheck, titulaire, banque, type, relationship, form, formValues } = props;

    const [changeTitulaire, setChangeTitulaire] = useState(false);
    const history = useHistory();
    const [listCountries, setListCountries] = useState(false);

    React.useEffect(() => {
        if (!listCountries) {
            fetchCountries();
        }
    }, [listCountries]);

    React.useEffect(() => {
        if (changeTitulaire) {
            changeValue('type', 'P');
            changeValue('birthdate', moment().subtract(14, 'year'));
        }
    }, [changeTitulaire]);

    async function fetchCountries() {
        let countries = await getCountries();
        let list = _.orderBy(countries, ['name'], ['asc']);

        list = list.map((c) => ({
            value: c.id,
            label: c.name,
        }));

        setListCountries(list);
    }
    const handleFormSubmit = () => {
        if (changeTitulaire  && relationship && relationship === 'A' && !formValues.files['G']?.name){
            Swal.fire({
                title: 'Attention',
                text: 'Merci de nous transmettre le RIB de votre client.',
                showConfirmButton: true,
                confirmButtonText: 'Terminer',
                showCloseButton: true
            })
        } else {
            handleSubmit();
        }
    }


    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Modifier les <span className='text-color-secondary'>Coordonnées bancaires</span> de votre client
                    </h3>

                    <div className='mt-5'>
                        <span className={'btn btn-outline-secondary'} onClick={() => history.goBack()}>
                            <i className={'fas fa-chevron-left mr-3'} /> Retour
                        </span>
                    </div>

                    <div className='mt-5 col-lg-6 col p-0 mx-auto'>
                        <Cadre icon={'fa fa-id-card'} title={'Informations actuelles'}>
                            <div className='mt-5 mt-lg-0'>
                                <p className={'max-width-4'}>
                                    IBAN :{' '}
                                    <b className={'d-block float-lg-right'}>{iban ? iban : "Vous n'avez aucun IBAN d'enregistré."}</b>
                                </p>
                                <p className={'max-width-4'}>
                                    Titulaire du compte : <b className={'d-block float-lg-right'}>{nomSouscripteur ?? banque.titulaire}</b>
                                </p>
                            </div>
                        </Cadre>
                        <form onSubmit={handleSubmit} className={'mt-5'}>
                            <span
                                className={'text-color-secondary hover-dark-color cursor-pointer'}
                                onClick={() => setChangeTitulaire(!changeTitulaire)}
                            >
                                <u>{changeTitulaire ? 'Ne pas changer le titulaire du compte' : 'Changer le titulaire du compte'}</u>
                            </span>

                            {changeTitulaire && (
                                <div className={'mt-4'}>
                                    <Field
                                        component={TextFieldRedux}
                                        name={'titulaire'}
                                        label={'Nom du titulaire du compte'}
                                        normalize={(v) => v.toUpperCase()}
                                    />

                                    {titulaire === nomSouscripteur || !titulaire ? (
                                        <>
                                            <Field component={TextFieldRedux} name={'adresse'}
                                                   label={'Adresse du titulaire'}/>

                                            <PostalCodeCity
                                                nameCodePostal={'cp'}
                                                nameVille={'ville'}
                                                label={'Code postal, ville'}
                                                changeValue={changeValue}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Field
                                                name='type'
                                                component={RadioFieldRedux}
                                                label={'Type de tiers payeur'}
                                                data={[
                                                    {value: 'P', label: 'Physique'},
                                                    {value: 'M', label: 'Morale'},
                                                ]}
                                                onChange={(e) => {
                                                    if (e.target.value === 'P') {
                                                        changeValue('birthdate', moment().subtract(16, 'year'));
                                                    } else {
                                                        changeValue('firstname', null);
                                                        changeValue('birthname', null);
                                                        changeValue('birthdate', null);
                                                        changeValue('birthcountry', null);
                                                        changeValue('birthcity', null);
                                                        changeValue('relationship', null);
                                                        changeValue('third_payeur_reason', null);
                                                    }
                                                }}
                                            />

                                            <Field
                                                name='lastname'
                                                component={TextFieldRedux}
                                                label={type === 'P' ? 'Nom' : 'Nom de la société'}
                                                normalize={(v) => v.toUpperCase()}
                                                maxLength={50}
                                            />
                                            {type === 'P' && (
                                                <>
                                                    <Field
                                                        name='firstname'
                                                        component={TextFieldRedux}
                                                        label={'Prénom'}
                                                        normalize={(v) => v.toUpperCase()}
                                                        maxLength={20}
                                                    />

                                                    <Field
                                                        name={'birthname'}
                                                        component={TextFieldRedux}
                                                        label={'Nom de naissance'}
                                                        normalize={(v) => v.toUpperCase()}
                                                        maxLength={20}
                                                    />

                                                    <Field
                                                        name={'birthdate'}
                                                        component={DatePickerFieldRedux}
                                                        label={'Date de naissance'}
                                                        maxDate={moment().subtract(14, 'Y').format('YYYY-MM-DD')}
                                                    />

                                                    <Field
                                                        name={'birthcity'}
                                                        component={TextFieldRedux}
                                                        label={'Ville de naissance'}
                                                        maxLength={250}
                                                    />
                                                    <Field
                                                        name={'birthcountry'}
                                                        component={Autocomplete}
                                                        label='Pays de naissance'
                                                        data={listCountries}
                                                    />

                                                    <Field
                                                        name='relationship'
                                                        component={SelectFieldRedux}
                                                        label={'Lien souscripteur / payeur'}
                                                        onChange={(e) => {
                                                            if (e.target.value !== 'A') {
                                                                changeValue('third_payeur_reason', null);
                                                            }
                                                        }}
                                                    >
                                                        <option value={''}>Sélectionnez une relation</option>
                                                        {tiersPayeurRelation.map((relation, index) => (
                                                            <option key={index} value={relation.code}>
                                                                {relation.libelle}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                    {relationship && relationship === 'A' ? (
                                                        <div className='col-6'>
                                                            <Field
                                                                name={'third_payeur_reason'}
                                                                component={TextFieldRedux}
                                                                label={"Motif d'intervention du payeur"}
                                                                maxLength={250}
                                                            />
                                                        </div>
                                                    ) : null}
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}

                            <div className='mt-4'>
                                <IbanField formValues={formValues} changeValue={changeValue} />

                                <p className={'font-italic'}>
                                    Attention, une mensualité / annuité peut encore être prélevée sur vos anciennes
                                    coordonnées bancaires.
                                </p>
                            </div>

                            {(changeTitulaire  && titulaire && titulaire !== nomSouscripteur) &&
                                <div className="my-5">
                                    <div className="alert alert-info" role="alert">
                                        Merci de nous transmettre le nouveau RIB de votre client.
                                    </div>

                                    <Field
                                        name='upload'
                                        component={UploadInlineContainer}
                                        formName={form}
                                        title={'RIB'}
                                        code={`files[G]`}
                                    />
                                </div>
                            }

                            <div className='text-center'>
                                {loading ? (
                                    <SmallSpinner/>
                                ) : (
                                    ibanIsCheck && (
                                        <button className={'btn btn-primary'}
                                                type={'button'}
                                                onClick={() => handleFormSubmit()}>
                                            Je valide les nouvelles coordonnées
                                            bancaires</button>
                                    )
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </Card>
        </div>
    );
};

ModifieRib.defaultProps = {};

ModifieRib.propTypes = {
    handleSubmit: PropTypes.func,
};

export default ModifieRib;
