import {connect} from 'react-redux'
import SideBar from './SideBar'
import {getLogoLightUrlWhitelabel} from "../../../../redux/selectors/whitelabel/whitelabel.selectors";
import {
    getMustPassByAPILON,
    getPermissionsUser,
    getRolesUser,
    getUserIsConcessionnaire
} from "../../../../redux/selectors/user/user.selectors";
import {
    getContractsWaitingStats, getReceiptsRejet,
    getReceiptsWaiting,
    getTotalStats
} from "../../../../redux/selectors/stats/stats.selectors";

const mapStateToProps = state => {
    return {
        hideSidebarAndHeader: window.location.pathname.includes('reprise'),
        logo: getLogoLightUrlWhitelabel(state),
        permissions: getPermissionsUser(state),
        userRoles: getRolesUser(state),
        isConcessionnaire: getUserIsConcessionnaire(state),
        stats: {
            total: getTotalStats(state),
            dossiersAttente: getContractsWaitingStats(state),
            impayes: getReceiptsWaiting(state),
            rejets: getReceiptsRejet(state)
        },
        mustPassAPRILON: getMustPassByAPILON(state)
    }
}

const SideBarContainer = connect(mapStateToProps)(SideBar)

export default SideBarContainer
