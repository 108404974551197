import React from 'react'
import motoImg from '../../../../assets/pictos/produits/moto.png';
import scooterImg from '../../../../assets/pictos/produits/scooter.png';
import cycloImg from '../../../../assets/pictos/produits/cyclo.png';
import quadImg from '../../../../assets/pictos/produits/quad.png';
import verteImg from '../../../../assets/pictos/produits/verte.png';
import autoImg from '../../../../assets/pictos/produits/auto.png'
import collectionImg from '../../../../assets/pictos/produits/collectionHome.png';
import trottinetteImg from '../../../../assets/pictos/produits/trottinette.png';
import veloImg from '../../../../assets/pictos/produits/velo.png';
import flotteImg from '../../../../assets/pictos/produits/flotte_icon.png';
import electriqueImg from '../../../../assets/pictos/produits/electrique.png';
import motoImgSelect from '../../../../assets/pictos/produits/moto-orange.png';
import scooterImgSelect from '../../../../assets/pictos/produits/scooter-orange.png';
import cycloImgSelect from '../../../../assets/pictos/produits/cyclo-orange.png';
import quadImgSelect from '../../../../assets/pictos/produits/quad-orange.png';
import verteImgSelect from '../../../../assets/pictos/produits/verte-orange.png';
import autoImgSelect from '../../../../assets/pictos/produits/auto-orange.png'
import collectionImgSelect from '../../../../assets/pictos/produits/collectionHome-orange.png';
import trottinetteImgSelect from '../../../../assets/pictos/produits/trottinette-orange.png';
import electriqueImgSelect from '../../../../assets/pictos/produits/electrique-vert.png';
import veloImgSelect from '../../../../assets/pictos/produits/velo-orange.png';
import flotteImgSelect from '../../../../assets/pictos/produits/flotte-orange.png';
import DeconnexionContainer from "./Deconnexion/DeconnexionContainer";
import AccueilContainer from "./Accueil/AccueilContainer";
import DevisDetailContainer from "./Devis/DevisDetail/DevisDetailContainer";
import ContractDetailContainer from "./Contracts/ContractDetail/ContractDetailContainer";
import DevisListeContainer from "./Devis/DevisListe/DevisListeContainer";
import ContractsListeContainer from "./Contracts/ContractsList/ContractsListeContainer";
import CommissionsContainer from "./Commissions/CommissionsContainer";
import GestionContainer from "./Gestion/GestionContainer";
import SouscriptionsContainer from "./Souscriptions/SouscriptionsContainer";
import AccidentDetailContainer
    from "./Contracts/ContractDetail/AccidentsContract/AccidentDetail/AccidentDetailContainer";
import QuittanceDetailContainer
    from "./Contracts/ContractDetail/QuittancesContract/QuittanceDetail/QuittanceDetailContainer";
import FormulaireProductsContainer from "./FormulaireProducts/FormulaireProductsContainer";
import CollectionContainer from "./FormulaireProducts/Collection/CollectionContainer";
import PublicitesContainer from "./Publicites/PublicitesContainer";
import DocumentsContainer from "./Documents/DocumentsContainer";
import ProduitsContainer from './Produits/ProduitContainer';
import ProfilContainer from "./Profil/ProfilContainer";
import ContactsContainer from "./Contacts/ContactsContainer";
import FeedBackContainer from "./FeedBack/FeedBackContainer";
import ArticlesContainer from "./Articles/ArticlesContainer";
import ArticleDetailContainer from "./Articles/ArticleDetail/ArticleDetailContainer";
import ImpayesContainer from "./Gestion/Impayes/ImpayesContainer";
import DashboardContainer from "./Dashboard/DashboardContainer";
import RejetsContainer from "./Gestion/Rejets/RejetsContainer";
import AccidentFormContainer from "./Contracts/ContractDetail/AccidentsContract/AccidentForm/AccidentFormContainer";
import GarantieMobiliteContainer from "./Contracts/ContractDetail/GarantieMobilite/GarantieMobiliteContainer";
import InfosPersoContainer from "./Contracts/ContractDetail/Avenants/InfosPerso/InfosPersoContainer";
import ModifieRibContainer from "./Contracts/ContractDetail/Avenants/ModifieRib/ModifieRibContainer";
import ChangeAddressContainer from "./Contracts/ContractDetail/Avenants/ChangeAddress/ChangeAddressContainer";
import ElectriqueContainer from "./FormulaireProducts/Electrique/ElectriqueContainer";
import ContractFleetContainer from "./Contracts/ContractDetail/Fleet/ContractFleet/ContractFleetContainer";
import OutFleetContainer from "./Contracts/ContractDetail/Fleet/ContractFleet/OutFleet/OutFleetContainer";
import ContractAddFleetContainer from "./Contracts/ContractDetail/AddFleet/ContractAddFleetContainer";
import ResilContratContainer from "./Contracts/ContractDetail/ResilContrat/ResilContratContainer";
import SendSignaturesContainer from "./Contracts/ContractDetail/Signatures/SendSignatures/SendSignaturesContainer";
import AjoutReparateurFormContainer
    from "./Contracts/ContractDetail/AccidentsContract/AjoutReparateurForm/AjoutReparateurFormContainer";
import EditionAttestationNveiContainer
    from "./Contracts/ContractDetail/EditionAttestationNvei/EditionAttestationNveiContainer";
import InfosNaissanceContainer from "./Contracts/ContractDetail/Form/InfosNaissance/InfosNaissanceContainer";
import EditTiersPayeurContainer from './Contracts/ContractDetail/AddTiersPayeur/EditTiersPayeurContainer';
import AddTiersPayeurContainer from './Contracts/ContractDetail/AddTiersPayeur/AddTiersPayeurContainer';
import EmailContainer from "./Contracts/ContractDetail/Form/Email/EmailContainer";
import ChangeFractionnementContainer
    from "./Contracts/ContractDetail/ChangeFractionnement/ChangeFractionnementContainer";
import HivernageContainer from "./Contracts/ContractDetail/Hivernage/HivernageContainer";
import ResiliationContainer
    from "@/components/App/Layout/Pages/Contracts/ContractDetail/Resiliation/ResiliationContainer";
import DeleteVehiculesFlotteContainer
    from "@/components/App/Layout/Pages/Contracts/ContractDetail/DeleteVehiculesFlotte/DeleteVehiculesFlotteContainer";
import ReloadContractContainer
    from "@/components/App/Layout/Pages/Contracts/ContractDetail/Resiliation/ReloadContract/ReloadContractContainer";

export const PagesRoutes = [
    /***** Sidebar *****/
    {
        path: '/accueil',
        sidebar: true,
        title: 'Accueil',
        permission: 'all',
        roles: ['courtier', 'concessionnaire', 'concessionnaire gp', 'concessionnaire premium'],
        main: (props) => <AccueilContainer {...props} />
    },
    {
        path: '/tableau-de-bord',
        sidebar: true,
        title: 'Tableau de bord',
        permission: 'read dashboard',
        roles: ['courtier', 'concessionnaire', 'concessionnaire gp', 'concessionnaire premium'],
        main: (props) => <DashboardContainer {...props} />
    },
    {
        path: '/mes-devis',
        sidebar: true,
        title: 'Mes devis',
        permission: 'read quotes',
        main: (props) => <DevisListeContainer {...props} />
    },
    {
        path: '/mes-souscriptions',
        sidebar: true,
        title: 'Mes souscriptions',
        permission: 'read quotes',
        main: (props) => <SouscriptionsContainer {...props} />
    },
    {
        path: '/mon-portefeuille',
        sidebar: true,
        title: 'Mon portefeuille',
        permission: 'read contracts',
        main: (props) => <ContractsListeContainer {...props} />
    },
    {
        path: '/mes-commissions',
        sidebar: true,
        title: 'Mes commissions',
        permission: 'read commissions',
        main: (props) => <CommissionsContainer {...props} />
    },
    {
        path: '/ma-gestion',
        sidebar: true,
        title: 'Ma gestion',
        permission: 'read contracts',
        roles: ['courtier', 'concessionnaire', 'concessionnaire gp', 'concessionnaire premium'],
        stats: 'total',
        children: [
            {
                path: '/ma-gestion/mes-dossiers-en-attente',
                sidebar: true,
                title: 'Dossiers en attente',
                permission: 'read contracts',
                stats: 'dossiersAttente',
                main: (props) => <GestionContainer {...props} />
            },
            {
                path: '/ma-gestion/mes-impayes',
                sidebar: true,
                title: 'Impayés',
                permission: 'read receipts',
                stats: 'impayes',
                main: (props) => <ImpayesContainer {...props} />
            },
            {
                path: '/ma-gestion/mes-rejets-de-prelevement',
                sidebar: true,
                title: 'Rejets de prélèvement',
                permission: 'read receipts',
                stats: 'rejets',
                main: (props) => <RejetsContainer {...props} />
            }
        ]
    },
    {
        path: '/mes-services',
        sidebar: true,
        title: 'Mes services',
        permission: 'all',
        roles: ['courtier'],
        main: (props) => <ProfilContainer {...props} />
    },
    {
        path: '/nos-produits',
        sidebar: true,
        title: 'Nos produits',
        permission: 'all',
        roles: ['courtier', 'concessionnaire', 'concessionnaire gp', 'concessionnaire premium'],
        children: [
            {
                path: '/produits/moto',
                sidebar: true,
                title: 'Moto',
                permission: 'all',
                main: (props) => <ProduitsContainer {...props} product={'moto'} />
            },
            {
                path: '/produits/scooter',
                sidebar: true,
                title: 'Scooter',
                permission: 'all',
                main: (props) => <ProduitsContainer {...props} product={'scooter'} />
            },
            {
                path: '/produits/50',
                sidebar: true,
                title: 'Cyclo 50',
                permission: 'all',
                main: (props) => <ProduitsContainer {...props} product={'50'} />
            },
            {
                path: '/produits/quad',
                sidebar: true,
                title: 'Quad',
                permission: 'all',
                main: (props) => <ProduitsContainer {...props} product={'quad'} />
            },
            {
                path: '/produits/verte',
                sidebar: true,
                title: 'Moto verte',
                permission: 'all',
                main: (props) => <ProduitsContainer {...props} product={'verte'} />
            },
            {
                path: '/produits/collection',
                sidebar: true,
                title: 'Collection & Loisirs',
                permission: 'all',
                roles: ['courtier'],
                main: (props) => <CollectionContainer {...props} />
            },
            {
                path: '/produits/trottinette',
                sidebar: true,
                title: 'Trottinette',
                permission: 'all',
                main: (props) => <ProduitsContainer {...props} product={'trottinette'} />
            },
            {
                path: '/produits/electrique',
                sidebar: true,
                title: '2-roues électrique',
                permission: 'all',
                main: (props) => <ElectriqueContainer {...props} />
            },
            {
                path: '/produits/velo',
                sidebar: true,
                title: 'Vélo',
                permission: 'all',
                main: (props) => <ProduitsContainer {...props} product={'velo'} />
            },
            {
                path: '/produits/livraison',
                sidebar: true,
                title: 'Livraison Cyclo 50',
                permission: 'all',
                roles: ['courtier'],
                main: (props) => <ProduitsContainer {...props} product={'livraison'} />
            },
        ]
    },
    {
        path: '/nos-documents',
        sidebar: true,
        title: 'Nos documents',
        permission: 'all',
        roles: ['courtier', 'concessionnaire', 'concessionnaire gp', 'concessionnaire premium'],
        main: (props) => <DocumentsContainer {...props} />
    },
    {
        path: '/publicites',
        sidebar: true,
        title: 'Flyers, Pubs',
        permission: 'order flyers',
        main: (props) => <PublicitesContainer {...props} />
    },
    {
        path: '/mes-contacts',
        sidebar: true,
        title: 'Mes contacts',
        permission: 'all',
        roles: ['courtier', 'concessionnaire', 'concessionnaire gp', 'concessionnaire premium'],
        main: (props) => <ContactsContainer {...props} />
    },
    /***** No Sidebar *****/
    {
        path: '/deconnexion',
        title: 'Déconnexion',
        permission: 'all',
        main: (props) => <DeconnexionContainer {...props} />
    },
    {
        sidebar: false,
        title: 'Formulaires',
        permission: 'create quote',
        children: [
            {
                title: 'Moto',
                path: '/mon-formulaire/moto',
                type: 'moto',
                png: motoImg,
                pngSelect: motoImgSelect,
                permission: 'create quote',
                main: (props) =>  <FormulaireProductsContainer {...props} product={'moto'} />
            },
            {
                title: 'Scooter >50cm3',
                path: '/mon-formulaire/scooter',
                type: 'scooter',
                png: scooterImg,
                pngSelect: scooterImgSelect,
                permission: 'create quote',
                main: (props) => <FormulaireProductsContainer {...props} product={'scooter'} />
            },
            {
                title: '50cm3',
                path: '/mon-formulaire/50',
                type: '50',
                png: cycloImg,
                pngSelect: cycloImgSelect,
                permission: 'create quote',
                main: (props) => <FormulaireProductsContainer {...props} product={'50'} />
            },
            {
                title: 'Quad',
                path: '/mon-formulaire/quad',
                type: 'quad',
                png: quadImg,
                pngSelect: quadImgSelect,
                permission: 'create quote',
                main: (props) => <FormulaireProductsContainer {...props} product={'quad'} />
            },
            {
                title: 'Moto Verte',
                path: '/mon-formulaire/verte',
                type: 'verte',
                png: verteImg,
                pngSelect: verteImgSelect,
                permission: 'create quote',
                main: (props) => <FormulaireProductsContainer {...props} product={'verte'} />
            },
            {
                title: 'Auto',
                path: '/mon-formulaire/auto',
                type: 'auto',
                png: autoImg,
                pngSelect: autoImgSelect,
                permission: 'create quote',
                main: (props) => <FormulaireProductsContainer {...props} product={'auto'} />
            },
            {
                title: 'Collection & Loisirs',
                path: '/mon-formulaire/collection',
                type: 'collection',
                png: collectionImg,
                pngSelect: collectionImgSelect,
                permission: 'create quote',
                main: (props) => <CollectionContainer {...props} />
            },
            {
                title: 'Trottinette électrique',
                path: '/mon-formulaire/trottinette',
                type: 'trottinette',
                png: trottinetteImg,
                pngSelect: trottinetteImgSelect,
                permission: 'create quote',
                main: (props) => <FormulaireProductsContainer {...props} product={'trottinette'} />
            },
            {
                title: '2-roues électrique',
                path: '/mon-formulaire/electrique',
                type: 'electrique',
                png: electriqueImg,
                pngSelect: electriqueImgSelect,
                permission: 'create quote',
                main: (props) => <ElectriqueContainer {...props} />
            },
            {
                title: 'Vélo',
                path: '/mon-formulaire/velo',
                type: 'velo',
                png: veloImg,
                pngSelect: veloImgSelect,
                permission: 'create quote',
                main: (props) => <FormulaireProductsContainer {...props} product={'velo'} />
            },
            {
                title: 'Livraison 50cm3',
                path: '/mon-formulaire/livraison',
                type: 'livraison',
                png: flotteImg,
                pngSelect: flotteImgSelect,
                permission: 'create quote',
                roles: ['courtier'],
                main: (props) => <FormulaireProductsContainer {...props} product={'livraison'} />
            },
            {
                title: 'Nouveau formulaire',
                path: '/mon-formulaire/new-form',
                type: 'new',
                png: null,
                pngSelect: null,
                permission: 'create quote',
                main: (props) => <FormulaireProductsContainer {...props} product={'all_new'} />
            }
        ]
    },
    {
        path: '/mon-devis/:id',
        sidebar: false,
        title: 'Mon devis',
        permission: 'read quote',
        main: (props) => <DevisDetailContainer {...props} />
    },
    {
        path: '/mon-devis/:id/reprise',
        sidebar: false,
        title: 'Reprise de mon devis',
        permission: 'read quote',
        main: (props) => <DevisDetailContainer {...props} reprise />
    },
    {
        path: '/mon-contrat/:id',
        sidebar: false,
        title: 'Mon contrat',
        permission: 'read contract',
        main: (props) => <ContractDetailContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/mon-sinistre/:ids',
        sidebar: false,
        title: 'Mon sinistre',
        permission: 'read contract',
        main: (props) => <AccidentDetailContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/mon-sinistre/:ids/ajouter-reparateur',
        sidebar: false,
        title: 'Ajout d\'un réparateur',
        permission: 'read contract',
        main: (props) => <AjoutReparateurFormContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/ma-quittance/:ids',
        sidebar: false,
        title: 'Ma quittance',
        permission: 'read receipts',
        main: (props) => <QuittanceDetailContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/declarer-un-sinistre/:idVehicule?',
        sidebar: false,
        title: 'Déclaration de sinistre',
        permission: 'all',
        main: (props) => <AccidentFormContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/ma-garantie-mobilite',
        sidebar: false,
        title: 'Ma garantie mobilité',
        permission: 'all',
        main: (props) => <GarantieMobiliteContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/modifier-les-informations-personnelles',
        sidebar: false,
        title: 'Modifier les informations personnelles',
        permission: 'all',
        main: (props) => <InfosPersoContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/ajout-tiers-payeur',
        exact: true,
        sidebar: false,
        title: 'Ajout d\'un tiers payeur',
        icon: '',
        permission: 'all',
        main: (props) => <AddTiersPayeurContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/edition-tiers-payeur/:ids',
        exact: true,
        sidebar: false,
        title: 'Modification tiers payeur',
        icon: '',
        permission: 'all',
        main: (props) => <EditTiersPayeurContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/modifier-les-informations-de-naissance',
        sidebar: false,
        title: 'Modifier les informations de naissance',
        permission: 'all',
        main: (props) => <InfosNaissanceContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/modifier-le-rib',
        sidebar: false,
        title: 'Modifier le RIB',
        permission: 'all',
        main: (props) => <ModifieRibContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/ma-flotte/vehicule/:ids',
        sidebar: false,
        title: 'Ma flotte',
        permission: 'all',
        main: (props) => <ContractFleetContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/ma-flotte/ajout-vehicule',
        sidebar: false,
        title: 'Ajout d\'un véhicule',
        permission: 'all',
        main: (props) => <ContractAddFleetContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/ma-flotte/sortie-vehicules',
        sidebar: false,
        title: 'Sortie de véhicules',
        permission: 'all',
        main: (props) => <DeleteVehiculesFlotteContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/ma-flotte/sortie-vehicule/:ids',
        sidebar: false,
        title: 'Sortie d\'un véhicule',
        permission: 'all',
        main: (props) => <OutFleetContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/procedure-signature',
        sidebar: false,
        title: 'Procédure signature',
        permission: 'all',
        main: (props) => <SendSignaturesContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/changement-fractionnement',
        sidebar: false,
        title: 'Changement de fractionnement',
        permission: 'all',
        main: (props) => <ChangeFractionnementContainer {...props} />
    },
    {
        path: '/mon-contrat/envoyer-email/pmq/1309563/:idTiers?/:idContract?',
        sidebar: false,
        title: 'Envoyer un email',
        permission: 'all',
        main: (props) => <EmailContainer className='loader' {...props} />
    },
    {
        path: '/mon-contrat/:id/hivernage',
        sidebar: false,
        title: 'Hivernage',
        permission: 'read contract',
        main: (props) => <HivernageContainer {...props} />
    },
    {
        path: '/feedback',
        sidebar: false,
        title: 'Donnez votre avis',
        permission: 'all',
        main: (props) => <FeedBackContainer {...props} />
    },
    {
        path: '/mon-article/:id',
        sidebar: false,
        title: 'Mon actualitée',
        permission: 'all',
        main: (props) => <ArticleDetailContainer {...props} />
    },
    {
        path: '/mon-actualite',
        sidebar: false,
        title: 'Mes actualitées',
        permission: 'all',
        main: (props) => <ArticlesContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/modifier-adresse',
        exact: true,
        sidebar: false,
        title: 'Modifier l\'adresse',
        permission: 'all',
        main: (props) => <ChangeAddressContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/resilier',
        exact: true,
        sidebar: false,
        title: 'Résilier / Suspendre le contrat',
        permission: 'all',
        main: (props) => <ResilContratContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/edition-attestation-assurance',
        exact: true,
        sidebar: false,
        title: 'Editer l\'attestation d\'assurance trottinette',
        permission: 'all',
        main: (props) => <EditionAttestationNveiContainer {...props} />
    },
    {
        path: '/mon-contrat/:id/resiliation',
        exact: true,
        sidebar: false,
        title: 'Résilier / Suspendre le contrat',
        permission: 'all',
        main: (props) => <ReloadContractContainer {...props} />
    }
]
