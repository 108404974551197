import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {
    setChangeAddress,
    setError,
    setHivernageReduction,
    setHivernageSuccess,
    setLoader
} from '../../../actions/app/ui/ui.actions'
import {
    AVENANT, POST_FIN_HIVERNAGE, POST_HIVERNAGE, POST_CHANGE_ADDRESS,
    POST_INFOS_PERSO_AVENANT,
    POST_MODIFIE_RIB_AVENANT, GET_HIVERNAGE_REDUC
} from "../../../actions/app/contracts/avenants.contract.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import _ from 'lodash'
import moment from 'moment';

export const avenantsMiddleware = () => next => action => {
    next(action)
    let clone
    let message
    let title
    let body

    switch (action.type){

        case POST_INFOS_PERSO_AVENANT:
            next([
                apiRequest({body: (action.payload.body), method: 'POST', url: action.payload.data, entity: POST_INFOS_PERSO_AVENANT, otherData: action.payload.meta}),
                setLoader({state: true, entity: action.payload.meta})
            ])
            break

        case POST_MODIFIE_RIB_AVENANT:
            body = action.payload.body;
            let data = new FormData()

            Object.keys(body).forEach(key => {
                if (key === 'files') {
                    Object.keys(body[key]).forEach(doc => {
                        data.append(`files[${doc}]`, body[key][doc])
                    })
                } else if(key === 'birthdate' && body['type'] && body['type'] === 'P' && body['birthdate'] && moment(body['birthdate']).isValid()){
                    data.append(key, moment(body['birthdate']).format('YYYY-MM-DD'))
                } else if(key === 'iban'){
                    data.append(key, body[key].replace(/[-]/g, ''))
                }else {
                    data.append(key, body[key])
                }
            });

            next([
                apiRequest({body: (data), method: 'POST', url: action.payload.data, entity: POST_MODIFIE_RIB_AVENANT, otherData: action.payload.meta}),
                setLoader({state: true, entity: action.payload.meta})
            ])
            break

        case POST_CHANGE_ADDRESS:
            body = action.payload.body;

            if(!body.sansStationnement) {
                if (body.new_adress_is_new_lieuStationnement === 'T') {
                    body.villeB = body.villeNew
                    body.codePostalB = body.codePostalNew
                } else {
                    body.villeB = body.ville_new_lieuStationnement
                    body.codePostalB = body.cp_new_lieuStationnement
                }
            }

            next([
                apiRequest({body: (body), method: 'POST', url: action.payload.data, entity: POST_CHANGE_ADDRESS, otherData: action.payload.meta}),
                setLoader({state: true, entity: action.payload.meta})
            ])
            break

        case POST_HIVERNAGE:
            clone = _.cloneDeep(action.payload.body);

            if(clone.dateFin){
                clone.dateFin = moment(clone.dateFin).format('YYYY-MM-DD')
            }

            next([
                apiRequest({body: clone, method: 'POST', url: action.payload.data, entity: POST_HIVERNAGE, otherData: action.payload.meta}),
                setLoader({state: true, entity: action.payload.meta})
            ])
            break

        case POST_FIN_HIVERNAGE:
            next([
                apiRequest({body: action.payload.body, method: 'POST', url: action.payload.data, entity: POST_FIN_HIVERNAGE, otherData: action.payload.meta}),
                setLoader({state: true, entity: action.payload.meta})
            ])
            break

        case `${POST_INFOS_PERSO_AVENANT} ${API_SUCCESS}`:
        case `${POST_MODIFIE_RIB_AVENANT} ${API_SUCCESS}`:
        case `${POST_CHANGE_ADDRESS} ${API_SUCCESS}`:
            let nextAction =[
                setNotification({entity: AVENANT, type: 'success', body: 'Votre demande a bien été enregistrée.', title: 'Merci !'}),
                setError({state: false, entity: action.payload.meta.otherData}),
                setLoader({state: false, entity: action.payload.meta.otherData})
            ]

            if (action.type === `${POST_CHANGE_ADDRESS} ${API_SUCCESS}`){
                let dataObj = {formName: action.payload.meta.otherData}

                if (action.payload.data.url){
                    dataObj.data = action.payload.data.url
                    window.open(action.payload.data.url, '_blank');
                }

                nextAction.push(setChangeAddress(dataObj))
            }

            next(nextAction)

            break

        case `${POST_INFOS_PERSO_AVENANT} ${API_ERROR}`:
        case `${POST_MODIFIE_RIB_AVENANT} ${API_ERROR}`:
        case `${POST_CHANGE_ADDRESS} ${API_ERROR}`:
        case `${POST_HIVERNAGE} ${API_ERROR}`:
            message = `<p>Une erreur est survenue.</p><p>${action.payload.data.response.data?.message ?? ''}</p>`
            next([
                setNotification({entity: AVENANT, type: 'error', body: message, title: 'Attention !', timeout: '0'}),
                setError({state: true, entity: action.payload.meta.otherData}),
                setLoader({state: false, entity: action.payload.meta.otherData})
            ])
            break

        case `${POST_HIVERNAGE} ${API_SUCCESS}`:
            message =  `<p class='font-weight-bold'>Votre demande a bien été enregistrée.</p><p>Pour valider la demande, votre client devra signer électroniquement la lettre avenant qui va lui être envoyée par e-mail</p>`;
            next([
                setNotification({entity: AVENANT, type: 'success', body: message, title: 'Merci !'}),
                setHivernageSuccess({state: true, entity: action.payload.meta.otherData}),
                setError({state: false, entity: action.payload.meta.otherData}),
                setLoader({state: false, entity: action.payload.meta.otherData})
            ])

            break

        case `${POST_FIN_HIVERNAGE} ${API_SUCCESS}`:
            message =  `<p class='font-weight-bold'>Ma période hivernale est bien désactivée.</p>`;
            next([
                setNotification({entity: AVENANT, type: 'success', body: message, title: 'Merci !'}),
                setHivernageSuccess({state: true, entity: action.payload.meta.otherData}),
                setError({state: false, entity: action.payload.meta.otherData}),
                setLoader({state: false, entity: action.payload.meta.otherData})
            ])

            break

        case `${POST_FIN_HIVERNAGE} ${API_ERROR}`:
            title = 'Attention !';
            if(action.payload.data?.response?.data?.message){
                message = `<p>${action.payload.data.response.data?.message ?? ''}</p>`
                title = null;
            } else {
                message = `<p>Une erreur est survenue.</p>`
            }
            next([
                setNotification({entity: AVENANT, type: 'error', body: message, title: title, timeout: '0'}),
                setError({state: true, entity: action.payload.meta.otherData}),
                setLoader({state: false, entity: action.payload.meta.otherData})
            ])
            break

        case GET_HIVERNAGE_REDUC:
            clone = _.cloneDeep(action.payload.body);
            clone.getTarif = 1;

            if(clone.dateFin){
                clone.dateFin = moment(clone.dateFin).format('YYYY-MM-DD')
            }

            next([
                apiRequest({body: clone, method: 'POST', url: action.payload.data, entity: GET_HIVERNAGE_REDUC, otherData: action.payload.meta}),
                setLoader({state: true, entity: action.payload.meta})
            ])
            break

        case `${GET_HIVERNAGE_REDUC} ${API_SUCCESS}`:
            next([
                setHivernageReduction({state: action.payload.data?.data?.reduction, entity: action.payload.meta.otherData}),
                setError({state: false, entity: action.payload.meta.otherData}),
                setLoader({state: false, entity: action.payload.meta.otherData})
            ])
            break

        case `${GET_HIVERNAGE_REDUC} ${API_ERROR}`:
            title = 'Attention !';
            if(action.payload.data?.response?.data?.message){
                message = `<p>${action.payload.data.response.data?.message ?? ''}</p>`
                title = null;
            } else {
                message = `<p>Une erreur est survenue.</p>`
            }
            next([
                setNotification({entity: GET_HIVERNAGE_REDUC, type: 'error', body: message, title: title, timeout: '0'}),
                setError({state: true, entity: action.payload.meta.otherData}),
                setLoader({state: false, entity: action.payload.meta.otherData})
            ])
            break

        default:
            break
    }
    return null
}
