import {API_ERROR, API_SUCCESS, apiRequest} from "@/redux/actions/core/api/api.actions";
import {setError, setLoader} from "@/redux/actions/app/ui/ui.actions";
import {setNotification} from "@/redux/actions/core/notifications/notifications.actions";
import {POST_TERMINATE} from "@/redux/actions/app/terminate/terminate.actions";
import _ from "lodash";
import moment from "moment/moment";

export const terminateMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){
        case POST_TERMINATE:
            let data = new FormData()
            let body = action.payload.body

            Object.keys(body).forEach(key => {
                if (key === 'files') {
                    Object.keys(body[key]).forEach(doc => {
                        data.append(`files[${doc}]`, body[key][doc])
                    })
                } else if (key === 'prenom' || key === 'nom') {
                    if (body[key] !== '') {
                        data.append(key, body[key])
                    }
                } else {
                    data.append(key, body[key])
                }
            });

            if(body.date_evenement){
                data.append('date_evenement', moment(body.date_evenement).format('YYYY-MM-DD'));
            }

            next([
                apiRequest({body: data, method: 'POST', url: payload.data, entity: POST_TERMINATE, otherData: action.payload.otherData}),
                setLoader({state: true, entity: POST_TERMINATE})
            ])
            break

        case `${POST_TERMINATE} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_TERMINATE}),
                setLoader({state: false, entity: POST_TERMINATE}),
                setNotification({
                    entity: POST_TERMINATE,
                    body: `Un accusé de réception a été envoyé par email`,
                    title: 'Demande envoyée',
                    type: 'success'
                }),
            ])
            window.location.pathname = `/mon-contrat/${action.payload.meta.otherData.idContract}`
            break

        case `${POST_TERMINATE} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_TERMINATE}),
                setLoader({state: false, entity: POST_TERMINATE}),
                setNotification({
                    entity: POST_TERMINATE,
                    body: `${payload.data.response.data?.message ?? ''}`,
                    title: 'Une erreur est survenue !',
                    type: 'error'
                }),
            ])
            break

        default:
            break
    }
    return null
}
