import React from 'react';
import UploadInlineContainer from "@/components/Commun/Inputs/Upload/UploadInline/UploadInlineContainer";
import {Field} from "redux-form";

const FieldsResilRib = () => {
    return (
        <>
            <div className="alert alert-info mt-5" role="alert">
                Suite à la résiliation, il est possible que nous devions rembourser votre client d’un trop-perçu.<br/>
                Merci de nous transmettre son RIB afin de sécuriser ce remboursement sur son compte.
            </div>

            <Field
                name='upload'
                component={UploadInlineContainer}
                formName='resil-3clics'
                title={'RIB'}
                code={`files[G]`}
            />
        </>
    );
};

export default FieldsResilRib;