import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../Commun/Spinners/Loader/Loader'
import Commissions from './Commissions'
import {COMMISSIONS, fetchCommissions} from "../../../../../redux/actions/app/commissions/commissions.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {getCommissionsLink} from "../../../../../redux/selectors/links/links.selectors";
import {getDadsCommissions, getDataCommissions} from "../../../../../redux/selectors/commissions/commissions.selectors";
import _ from "lodash";
import {getBelairIdUser} from "../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        commissions: getDataCommissions(state),
        user_id: getBelairIdUser(state),
        dads: getDadsCommissions(state),
        query: getCommissionsLink(state),
        loaded: getLoadingEntity(state, COMMISSIONS) === false,
        error: getErrorEntity(state, COMMISSIONS)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchCommissions: ({query}) => fetchCommissions({query})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, user_id } = stateProps
    const { fetchCommissions } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchCommissions({query: _.replace(_.replace(query, '{id}', user_id), '{type}', 'belair')}),
    }
}

const CommissionsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Commissions)

export default CommissionsContainer
