import React from 'react';
import {Link, NavLink} from "react-router-dom";
import './sidebar.scss'
import Dropdown from "./Dropdown/Dropdown";
import {PagesRoutes} from '../Pages/PagesRoutes'
import _ from 'lodash'
import { Scrollbars } from 'react-custom-scrollbars-2'
import newImg from "../../../../assets/images/publicites/new.svg";

const SideBar = (props) => {
    const { logo, permissions, stats, hideSidebarAndHeader, userRoles, isConcessionnaire, mustPassAPRILON } = props

    const checkRoles = route => {
        if (route.roles){
            let hasRole = false;
            _.map(userRoles, ur => {
                if(_.includes(route.roles, ur)){
                    hasRole = true;
                }
            });

            return hasRole
        }

        return true;
    }

    return (
        <div id={'sidebar'} className={`bg-white position-fixed h-100 p-2 ${hideSidebarAndHeader && 'd-none'}`}>
            <Scrollbars>
                <div className={'px-4 pt-0 text-center'}>
                    <Link to="/">
                        <img src={logo} alt="Logo" className={'img-fluid mb-2'} />
                    </Link>
                </div>

                <div className={'mt-4 pl-1'}>
                    {
                        PagesRoutes.map(r => (
                            r.sidebar && checkRoles(r)
                                ? r.children
                                    ? (_.includes(permissions, r.permission) || r.permission === 'all') && ((mustPassAPRILON && r.title !== "Nos produits") || (!mustPassAPRILON)) && <Dropdown mustPassAPRILON={mustPassAPRILON} key={r.path} checkRoles={checkRoles} childrenRoutes={r.children} title={r.title} icon={r.icon} permissions={permissions} stats={stats} rStats={r.stats}/>
                                    : (_.includes(permissions, r.permission) || r.permission === 'all') && ((mustPassAPRILON && r.title !== "Mes devis") || (!mustPassAPRILON)) && <NavLink key={r.path} to={r.path} className={'navlink d-block text-dark text-decoration-none my-4'}>
                                    {(!isConcessionnaire && r.new) && <img src={newImg} style={{zIndex: 10}} alt="Nouveauté" width={35} className={'position-absolute badge d-block'}/> }
                                    {r.icon && <i className={`${r.icon} mr-2 text-color-secondary`} /> } <span className="title-navlink d-inline-block">{r.title}</span>
                                </NavLink>
                                : null
                        ))
                    }
                </div>
            </Scrollbars>
        </div>
    );
};

export default SideBar;


/*
r.sidebar
? r.children
    ? (_.includes(permissions, r.permission) || r.permission === 'all') && <Dropdown key={r.path} childrenRoutes={r.children} title={r.title} icon={r.icon} permissions={permissions} stats={stats} rStats={r.stats}/>
    : (_.includes(permissions, r.permission) || r.permission === 'all') && <NavLink key={r.path} to={r.path} className={'navlink d-block text-dark text-decoration-none my-4'}>
        {r.icon && <i className={`${r.icon} mr-2 text-color-secondary`} /> } <span className="title-navlink d-inline-block">{r.title}</span>
    </NavLink>
*/
