import React, {Component} from 'react';
import './dropdown.scss'
import {NavLink} from "react-router-dom";
import _ from "lodash";

class Dropdown extends Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
        this.state = {collapse: false}
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false)
    }

    handleClick(e) {
        if (this.node && this.node.contains(e.target)) {
            return
        }

        this.setState({collapse: false})
    }

    render(){
        const { stats, childrenRoutes, title, icon, permissions, rStats, checkRoles } = this.props
        const { collapse } = this.state

        return (
            <div ref={node => {this.node = node}}>
                <div className={`navlink text-dark my-3 ${collapse && 'active'} position-relative`}
                     onClick={() => this.setState({collapse: !collapse})}
                >
                    {icon && <i className={`${icon} mr-2 text-color-secondary`} />} <span className={'title-navlink d-inline-block'}>{title}</span>
                    <i className="fas fa-chevron-right ml-2" />
                    {stats[rStats] > 0 && <span className="border-0 badge badge-pill badge-warning position-absolute text-white">{stats[rStats] > 100 ? '+100' : stats[rStats]}</span>}
                </div>

                <div className={`background-light content-dropdown ${collapse && 'active'}`}>
                    {
                        childrenRoutes.map(r => (
                            (checkRoles(r) && (_.includes(permissions, r.permission) || r.permission === 'all')) && <div key={r.path}>
                                <div className={'position-relative pt-3 mt-2'}>
                                    <NavLink to={r.path} className={'d-block text-dark text-decoration-none'}>
                                        <span className={'ml-2 d-inline-block'}>{r.title}</span>
                                        {stats[r.stats] > 0 && <span className="border-0 badge badge-pill badge-warning text-white position-absolute">{stats[r.stats] > 100 ? '+100' : stats[r.stats]}</span>}
                                    </NavLink>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default Dropdown;
