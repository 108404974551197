import React, {useState} from 'react';
import _ from "lodash";
import moment from "moment";
import {getMotif} from "@/utils/getMotif";
import Swal from "sweetalert2";
import TextFieldRedux from "@/components/Commun/Inputs/Text/TextFieldRedux";
import {Field} from "redux-form";
import SelectFieldRedux from "@/components/Commun/Inputs/Select/SelectFieldRedux";
import DatePickerFieldRedux from "@/components/Commun/Inputs/Date/DatePickerFieldRedux";
import RadioDesignButton from "@/components/Commun/Inputs/Radio/RadioDesignButton/RadioDesignButton";
import TextAreaFieldRedux from "@/components/Commun/Inputs/Text/TextAreaFieldRedux";
import UploadInlineContainer from "@/components/Commun/Inputs/Upload/UploadInline/UploadInlineContainer";
import Card from '../../../../../../Commun/Card/Card';
import {useHistory} from "react-router-dom";
import FieldsResilRib
    from "@/components/App/Layout/Pages/Contracts/ContractDetail/Resiliation/FieldsResilRib/FieldsResilRib";

const Resiliation = (props) => {
    const { contract, handleSubmit, formValues } = props

    const history = useHistory();
    const [requiredFile, setRequiredFile] = useState(false)
    const [msgInfo, setMsgInfo] = useState('')
    const [showRecontact, setShowRecontact] = useState(false)
    const [showRecap, setShowRecap] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const diffDaysAfnToday = moment().diff(contract.situation.dateAffaireNouvelle, 'days')
    const diffDaysAvtToday = moment().diff(contract.situation.date, 'days')
    const diffEcheanceToday = moment().diff(contract.situation.dateEcheance, 'days')

    const handleClickNextStep = (values) => {
        if (values.motif === 'A' && !values.files['B']?.name){
            Swal.fire({
                title: 'Attention',
                text: 'Merci de nous transmettre le certificat de cession ou la carte barrée et signée.',
                showConfirmButton: true,
                confirmButtonText: 'Terminer',
                showCloseButton: true
            })
        } else if(values.motif === 'vol' && !values.files['B']?.name) {
            Swal.fire({
                title: 'Attention',
                text: 'Merci de nous transmettre le procès-verbal de vol. Si vous ne l\'avez pas, nous vous invitons à déposer une plainte auprès des forces de l\'ordre',
                showConfirmButton: true,
                confirmButtonText: 'Terminer',
                showCloseButton: true
            })
        } else if(values.motif === 'situation' && !values.commentaire) {
            Swal.fire({
                title: 'Attention',
                text: 'Merci de préciser votre situation en commentaire',
                showConfirmButton: true,
                confirmButtonText: 'Terminer',
                showCloseButton: true
            })
        } else if(values.motif === 'autre' && !values.commentaire) {
            Swal.fire({
                title: 'Attention',
                text: 'Merci de préciser votre demande en commentaire',
                showConfirmButton: true,
                confirmButtonText: 'Terminer',
                showCloseButton: true
            })
        } else if (values.motif === 'A' && !values.susp_res) {
            Swal.fire({
                title: 'Attention',
                text: 'Merci de préciser si vous souhaitez suspendre ou résilier votre contrat',
                showConfirmButton: true,
                confirmButtonText: 'Terminer',
                showCloseButton: true
            })
        } else {
            setShowRecap(true)
            window.scroll(0,0)
        }
    }

    const onChangeMotifResil = (changeValue, value) => {
        setMsgInfo('')
        changeValue('motif', value);
        setRequiredFile(false);
        setShowRecontact(false);
        changeValue('date_evenement', undefined);

        const setCommonFields = (action, motif_refus = null) => {
            changeValue('action', action);
            if (motif_refus) {
                changeValue('motif_refus', motif_refus);
            }
        };

        switch (value) {
            case 'echeance':
                setShowRecontact(true);
                if (diffEcheanceToday > 335 || (diffEcheanceToday <= -90 && diffEcheanceToday >= -335)) {
                    setCommonFields('ag_res');
                    changeValue('date_ag_res', moment(contract?.situation.dateEcheance).subtract(60, 'days').format('YYYY-MM-DD'));
                } else if (diffEcheanceToday <= -60 && diffEcheanceToday >= -90) {
                    if(!contract.prelevement) {
                        setCommonFields('create_res_esp');
                    } else {
                        setCommonFields('terminate');
                        changeValue('date_evenement', moment(contract?.situation.dateEcheance).subtract(1, 'days'));
                        changeValue('motif', 'B')
                    }
                } else if (diffEcheanceToday <= 0 && diffEcheanceToday >= -60) {
                    setCommonFields('create_res');
                } else if (diffEcheanceToday <= 30) {
                    setShowRecontact(false);
                    setCommonFields('create_res');
                } else {
                    setCommonFields('create_res_esp');
                }
                break;
            case 'Q':// Résiliation Rétractation souscription
                if (diffDaysAfnToday <= 14) {
                    if (!contract.prelevement) {
                        setCommonFields('create_res_esp');
                    } else {
                        changeValue('date_evenement', moment());
                        setCommonFields('terminate');
                    }
                } else {
                    setCommonFields('send_mail_refus', 'Q.1');
                }
                break;
            case '0':// Résiliation loi hamon
                setShowRecontact(true);

                let motif_refus;
                if (diffDaysAfnToday < 365) {
                    motif_refus = '0.1';
                } else if (contract.souscripteur.titre === 'SOC' || contract.vehicule.usage === 'MA - Affaires') {
                    motif_refus = '0.2';
                } else {
                    motif_refus = '0.3';
                }

                setCommonFields('send_mail_refus', motif_refus);
                break;
            case 'A':// Résiliation suite à vente
                if (!contract.prelevement) {
                    setCommonFields('create_res_esp');
                } else {
                    changeValue('action', 'create_res_vente');
                }
                setRequiredFile(true);
                break;
            case '3':// Résiliation suite refus d'avenant
                if (diffDaysAvtToday <= 30) {
                    if (!contract.prelevement) {
                        setCommonFields('create_res_esp');
                    } else {
                        changeValue('date_evenement', moment().add(10, 'days'));
                        setCommonFields('terminate');
                    }
                } else {
                    setCommonFields('send_mail_refus', '3.1');
                }
                break;
            case 'prime':
                setShowRecontact(true);
                setCommonFields('create_res_esp');
                break;
            case 'situation':
                setRequiredFile(true);
                setCommonFields('create_res_esp');
                break;
            case 'vol':
                if (!_.find(contract.garanties, ['code_famille', 'VI'])) {
                    changeValue('action', 'create_res_esp');
                    setRequiredFile(true);
                } else {
                    setRequiredFile(false);
                    if(!_.isEmpty(contract.accidents)) {
                        _.map(contract.accidents, function (accident) {
                            if (_.includes(accident, ['categorieCode', ['10.0', '105.1', '9.0', '105.2', '18.0', '19.0']])) {
                                setMsgInfo('Vous devez dans un premier temps déclarer le vol du véhicule sur votre espace professionnel, puis le contrat de votre client sera automatiquement suspendu 30 jours après le vol.');
                            } else {
                                setMsgInfo('Suite à la déclaration pour vol, le contrat de votre client sera suspendu automatiquement 30 jours après le vol.');
                            }
                        });
                    } else {
                        setMsgInfo('Vous devez dans un premier temps déclarer le vol du véhicule sur votre espace professionnel, puis le contrat de votre client sera automatiquement suspendu 30 jours après le vol.');
                    }
                }
                break;
            case 'X':// NON ACHAT
                if (contract?.situation.statutCode === '2' && diffDaysAfnToday < 30) {
                    if (!contract.prelevement) {
                        setCommonFields('create_res_esp');
                    } else {
                        changeValue('action', 'terminate');
                        changeValue('date_evenement', moment(contract.situation.dateAffaireNouvelle));
                    }
                }
                break;
            case 'autre':
                setRequiredFile(true);
                setCommonFields('create_res_esp');
                break;
            default:
                break;
        }
    }

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        Résiliation <span className="text-color-secondary">du contrat</span> de votre client
                    </h3>

                    <div className="mt-5">
                        <span className={'btn btn-outline-secondary'} onClick={() => history.goBack()}><i
                            className={'fas fa-chevron-left mr-3'} /> Retour</span>
                    </div>

                    <form onSubmit={handleSubmit} className={'mt-5 col-lg-6 col-md-12 mx-auto'}>
                        {(!showRecap &&
                            <div className={'formulaire'}>
                                <div className={'mb-4 mt-4'}>
                                    <Field
                                        component={TextFieldRedux}
                                        name={'police'}
                                        label={'Numéro d’adhésion'}
                                        disabled
                                    />
                                </div>
                                <div className={'mb-4 mt-4'}>
                                    <Field
                                        component={TextFieldRedux}
                                        name={'nom'}
                                        label={'Nom du souscripteur'}
                                        disabled
                                    />
                                </div>
                                <div className={'mb-4 mt-4'}>
                                    <Field
                                        component={TextFieldRedux}
                                        name={'prenom'}
                                        label={'Prénom du souscripteur'}
                                        disabled
                                    />
                                </div>
                                <div className={'mb-4 mt-4'}>
                                    <Field
                                        component={TextFieldRedux}
                                        name={'email'}
                                        label={'Adresse email de contact'}
                                    />
                                </div>
                                <div className={'mb-4 mt-4'}>
                                    <Field
                                        name='motif'
                                        component={SelectFieldRedux}
                                        label={'Quel est le motif de votre résiliation ?'}
                                        onChange={(event, newValue) => onChangeMotifResil(props.change, newValue)}
                                    >
                                        <option value={''}>Sélectionnez votre motif</option>
                                        <option value={'Q'}>Résiliation rétractation 14 jours</option>
                                        <option value={'vol'}>Résiliation pour vol de véhicule</option>
                                        {(contract?.situation.statutCode === '2' && diffDaysAfnToday < 30) && <option value={'X'}>Résiliation pour non achat</option>}
                                        <option value={'A'}>Résiliation suite à vente</option>
                                        {contract?.situation.statutCode !== '2' && <>
                                            <option value={'echeance'}>Résiliation à échéance / loi Chatel</option>
                                            {(!contract.vehicule.codeProduit === '7311_VAE' && !contract.vehicule.codeProduit === '7311_NVEI') &&
                                                <option value={'0'}>Résiliation Loi Hamon</option>
                                            }
                                            <option value={'3'}>Résiliation suite à un refus d’avenant</option>
                                            <option value={'prime'}>Résiliation pour augmentation de prime</option>
                                            <option value={'situation'}>Résiliation pour changement de situation (adresse, profession...)</option>
                                            <option value={'autre'}>Résiliation pour un autre motif</option>
                                        </>}
                                    </Field>
                                </div>
                                {
                                    formValues?.date_evenement && <>
                                        {formValues?.motif === '3' &&
                                            <div className="alert alert-info mt-5" role="alert">
                                                Lors d'un refus d'avenant, la résiliation est effective 10 jours après la demande.
                                            </div>
                                        }
                                        <div className={'mb-4 mt-4'}>
                                            <Field
                                                name={'date_evenement'}
                                                component={DatePickerFieldRedux}
                                                label={'Date de l\'évènement donnant lieu à la résiliation'}
                                            />
                                        </div>
                                    </>
                                }

                                {
                                    formValues?.motif === 'A' &&
                                    <Field
                                        name={`susp_res`}
                                        component={RadioDesignButton}
                                        label={'Souhaitez vous suspendre ou résilier votre contrat ?'}
                                        data={[
                                            { value: "suspension", label: "Suspendre" },
                                            { value: "resiliation", label: "Résilier" },
                                        ]}
                                        required={formValues.motif === 'A'}
                                    />
                                }

                                {
                                    showRecontact &&
                                    <Field
                                        name={`recontact`}
                                        component={RadioDesignButton}
                                        label={formValues.motif === '0' ? "Vous ne pouvez pas effectuer de demande de résiliation pour Loi Hamon. En effet, c'est à la nouvelle compagnie d'assurance de faire la demande après l'accord de votre client. Souhaitez-vous être recontacté pour en discuter ?" : "Il est encore temps de revoir le contrat de votre client avant sa prochaine échéance, et si nous en discutions ?"}
                                        data={[
                                            {value: "1", label: "Oui"},
                                            {value: "0", label: "Non"},
                                        ]}
                                    />
                                }

                                {(formValues?.motif === 'situation' || formValues?.motif === 'autre') &&
                                    <Field
                                        name='commentaire'
                                        component={TextAreaFieldRedux}
                                        label={'Commentaire'}
                                        maxLength={'255'}
                                        subLabel={'255 caractères maximum'}
                                    />
                                }

                                {requiredFile &&
                                    <>
                                        {formValues.motif === 'vol' &&
                                            <div className="alert alert-info mt-5" role="alert">
                                                Merci de nous transmettre le procès-verbal de vol. Si vous ne l'avez pas, nous vous invitons à demander à votre client de déposer une plainte auprès des forces de l'ordre.
                                            </div>
                                        }
                                        {formValues.motif === 'A' &&
                                            <div className="alert alert-info mt-5" role="alert">
                                                Merci de nous transmettre le certificat de cession ou la carte barrée et signée.
                                            </div>
                                        }

                                        <Field
                                            name='upload'
                                            component={UploadInlineContainer}
                                            formName='resil-3clics'
                                            title={'Pièce justificative'}
                                            code={`files[B]`}
                                        />
                                    </>
                                }

                                {(!contract.prelevement && formValues.motif && formValues.motif !== 'vol' && !showRecontact) &&
                                    <FieldsResilRib/>
                                }

                                {msgInfo
                                    ? <div className={'alert-warning'}><p>{msgInfo}</p></div>
                                    : <div className="d-flex my-5 align-items-center justify-content-between">
                                    <span className={`btn btn-primary w-100`}
                                          onClick={() => handleClickNextStep(formValues)}>
                                        Je valide
                                    </span>
                                    </div>
                                }
                            </div>
                        )}
                        {showRecap && <div className={'recap'}>
                            <div className={'bloc-white'}>
                                <div>
                                    <h4 className={'fw-bold text-primary-light d-inline-block'}>Récapitulatif
                                        de votre demande</h4>
                                </div>
                                <div className={'mt-4'}>
                                    <div className="row">
                                        <div className="col">
                                            <p className={'mb-1 fw-bold'}>Nom et prénom</p>
                                            <p className={'m-0 text-secondary'}>{formValues.nom} {formValues.prenom}</p>
                                        </div>
                                        <div className="col">
                                            <p className={'mb-1 fw-bold'}>Numéro d’adhésion</p>
                                            <p className={'m-0 text-secondary'}>{formValues.police}</p>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row">
                                        <div className="col">
                                            <p className={'mb-1 fw-bold'}>Motif de votre
                                                résiliation</p>
                                            <p className={'m-0 text-secondary'}>{getMotif(formValues.motif)}</p>
                                        </div>
                                        {formValues.date_evenement &&
                                            <div className="col">
                                                <p className={'mb-1 fw-bold'}>Date d'effet de votre
                                                    résiliation</p>
                                                <p className={'m-0 text-secondary'}>{moment(formValues.date_evenement).format('L')}</p>
                                            </div>
                                        }
                                    </div>
                                    {formValues.commentaire &&
                                        <div className="row">
                                            <div className="col">
                                                <p className={'mb-1 fw-bold'}>Commentaire</p>
                                                <p className={'m-0 text-secondary'}>{formValues.commentaire}</p>
                                            </div>
                                        </div>
                                    }

                                    {(!contract.prelevement && formValues.recontact !== "1" && formValues.motif && showRecontact) &&
                                        <FieldsResilRib/>
                                    }
                                </div>
                                <hr />
                                <div className={'d-flex flex-column flex-md-row'}>
                                    <button disabled={buttonDisabled} type={'submit'} className={'btn btn-primary col-lg-6 col-12'}>{formValues.recontact === "1" ? 'Confirmer ma demande de rappel' : 'Confirmer ma demande de résiliation'}</button>
                                    <span className={`ms-lg-4 btn btn-secondary col-lg-6 col-12 my-2 my-lg-0`} onClick={() => setShowRecap(false)}>
                                        Annuler
                                    </span>
                                </div>
                            </div>
                        </div>}
                    </form>
                </div>
            </Card>
        </div>
    );
};

export default Resiliation;