import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import loader from '../../../../../Commun/Spinners/Loader/Loader'
import DevisListe from './DevisListe'
import {getQuotesLink} from "../../../../../../redux/selectors/links/links.selectors";
import {fetchQuote} from "../../../../../../redux/actions/app/quotes/quotes.actions";
import {getMustPassByAPILON, getUserMustRedirectAprilOn} from "@/redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        query: getQuotesLink(state),
        filters: {sort: '-id'},
        infosAprilOn: getUserMustRedirectAprilOn(state),
        mustPassAPRILON: getMustPassByAPILON(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchQuote: ({query, filters}) => fetchQuote({query, filters})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, filters } = stateProps
    const { fetchQuote } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => fetchQuote({query, filters}),
        fetchQuote: ({filters}) => fetchQuote({query, filters})
    }
}

const DevisListeContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(DevisListe)

export default DevisListeContainer
